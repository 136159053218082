import { ExperienceIcon } from 'assets/icons/DetailedProfile';
import React from 'react';
import SectionTitle from './SectionTitle';
import SingleExperience from './SingleExperience';
import ExperienceComment from './ExperienceComment';

interface PropsType {
  candidate: any;
  exp: any;
  showTitle?: any;
}

const Styles: any = {
  root: {
    padding: '10px 12px 4px',
    // paddingLeft: '12px',
    // paddingRight: '12px',
    boxShadow: '0px 0px 4px #00000040',
    borderRadius: 10,
    // paddingBottom: 4,
    width: '100%',
    maxWidth: '320px',
  },
  body: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: '4px',
  },
};

function mergeAndSortExperiences(experiences: any[]) {
  const mergedExperiences = [...experiences]; // Clone the experiences array to avoid mutations

  for (let i = 0; i < mergedExperiences.length; i++) {
    for (let j = i + 1; j < mergedExperiences.length; j++) {
      const companyI = mergedExperiences[i]?.company?.toLowerCase();
      const companyJ = mergedExperiences[j]?.company?.toLowerCase();

      if (companyI === companyJ) {
        if (!mergedExperiences[i].mergedData) {
          mergedExperiences[i].mergedData = [mergedExperiences[i]]; // Initialize mergedData array
        }

        mergedExperiences[i].mergedData.push(mergedExperiences[j]); // Add the matching experience
        mergedExperiences.splice(j, 1); // Remove the duplicate experience
        j--; // Adjust index after splice
      }
    }

    // Sort nested experiences in mergedData by startYear and endYear
    if (mergedExperiences[i]?.mergedData) {
      mergedExperiences[i].mergedData.sort((a: any, b: any) => {
        const startYearA = Number(a.startYear) || 0;
        const startYearB = Number(b.startYear) || 0;
        const endYearA = Number(a.endYear) || 0;
        const endYearB = Number(b.endYear) || 0;

        if (startYearA !== startYearB) {
          return startYearB - startYearA; // Sort by startYear descending
        }
        return endYearB - endYearA; // Sort by endYear descending
      });
    }
  }

  // Sort the outer array of experiences by startYear and endYear
  mergedExperiences.sort((a: any, b: any) => {
    const startYearA = Number(a.startYear) || 0;
    const startYearB = Number(b.startYear) || 0;
    const endYearA = Number(a.endYear) || 0;
    const endYearB = Number(b.endYear) || 0;

    if (startYearA !== startYearB) {
      return startYearB - startYearA; // Sort by startYear descending
    }
    return endYearB - endYearA; // Sort by endYear descending
  });

  return mergedExperiences;
}

const Experience: React.FC<PropsType> = ({
  candidate,
  exp,
  showTitle = true,
}) => {
  const processedExperiences = mergeAndSortExperiences(exp);
  const column1: any = [];
  const column2: any = [];
  processedExperiences.forEach((experience: any, index: any) => {
    const card = (
      <div
        key={`${index}-experience`}
        style={{ marginBottom: '3px', marginTop: '3px' }}
      >
        <SingleExperience
          index={index}
          experience={experience}
          candidateId={candidate?._id}
        />
        {experience?.comments && <ExperienceComment experience={experience} />}
      </div>
    );

    // if (index < exp?.length / 2 || exp?.length<3) {
    column1.push(card);
    // } else {
    //   column2.push(card);
    // }
    // if (index % 2 === 0) {
    //   column1.push(card);
    // } else {
    //   column2.push(card);
    // }
  });

  return processedExperiences?.length > 0 ? (
    <div style={Styles.root}>
      {showTitle && (
        <SectionTitle
          title="Experience"
          icon={ExperienceIcon}
          fontSize="13px"
          fontWeight="600"
          actionType="add"
          actionText="Add experience"
        />
      )}
      <div style={{ display: 'flex', gap: '8px' }}>
        <div style={Styles.column}>{column1}</div>
        {/* <div style={{...Styles.column,display:!column2?.length&&'none'}}>{column2}</div> */}
      </div>
    </div>
  ) : null;
};

export default Experience;
