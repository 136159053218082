import React from 'react';
import Cdp from '../pages/Cdp';

interface Props {
  posIndex: any;
  index: any;
  jobData: any;
  canadidates: any;
  iconToShow: any;
}

const ShowCompleteCdp: React.FC<Props> = ({
  posIndex,
  index,
  jobData,
  canadidates,
  iconToShow,
}) => {
  const candidate = canadidates[posIndex]?.candidates[index]?.candidate;
  const isShow =
    jobData?.getJobDetails &&
    canadidates[posIndex]?.candidates[index] &&
    candidate;

  const isYearlyCompensation =
    candidate?.yearlyCompensation != null &&
    candidate?.yearlyCompensation?.current != null;

  // Function to group experiences by company
  function groupExperiencesByCompany(experiences: any[]) {
    const grouped = experiences.reduce((acc: any, exp: any) => {
      const companyName = exp.company || 'Unknown Company';
      if (!acc[companyName]) {
        acc[companyName] = [];
      }
      acc[companyName].push(exp);
      return acc;
    }, {});

    return Object.values(grouped).map((group: any) =>
      group.sort((a: any, b: any) => {
        const startYearA = Number(a.startYear) || 0;
        const startYearB = Number(b.startYear) || 0;
        const endYearA = Number(a.endYear) || 0;
        const endYearB = Number(b.endYear) || 0;

        if (startYearA !== startYearB) {
          return startYearB - startYearA; // Sort by startYear descending
        }
        return endYearB - endYearA; // Sort by endYear descending
      })
    );
  }

  // Function to create subsets for experiences and education
  function createSubsets(objectsArray: any, size: any) {
    const subsets: any = [];
    let currentSubset: any = [];
    let currentCharCount = 0;
    let commentsCount = 0;

    objectsArray?.forEach((obj: any) => {
      const positionLength = obj.position ? obj.position.length : 0;
      const commentsLength = obj.comments ? obj.comments.length : 0;
      const totalLength = positionLength + commentsLength;

      const maxObjectsInSubset = commentsCount > 20 ? 3 : size < 4 ? 4 : size;
      const maxCharacters = commentsCount > 0 ? 600 : 450;

      if (
        currentSubset.length < maxObjectsInSubset &&
        currentCharCount + totalLength <= maxCharacters
      ) {
        currentSubset.push(obj);
        currentCharCount += totalLength;
        if (commentsLength > 0) {
          commentsCount++;
        }
      } else {
        if (currentSubset.length > 0) {
          subsets.push(currentSubset);
        }
        currentSubset = [obj];
        currentCharCount = totalLength;
        commentsCount = commentsLength > 0 ? 1 : 0;
      }
    });

    if (currentSubset.length > 0) {
      subsets.push(currentSubset);
    }

    return subsets;
  }

  const candidateExp =
    candidate?.experiences &&
    candidate?.experiences[0] != null &&
    candidate?.experiences[0]?.length > 0 &&
    candidate?.experiences[0]?.flat()?.flat();

  const candidateEdu =
    candidate?.education?.length > 0 && candidate?.education?.flat()?.flat();

  // Group experiences by company and sort within each group
  const groupedExp = candidateExp
    ? groupExperiencesByCompany(candidateExp)
    : [];

  // Flatten all grouped experiences into a single array for the next step
  const flattenedExperiences = groupedExp.flat();
  let maxLength = 0;
  groupedExp.forEach((group) => {
    if (group.length > maxLength) {
      maxLength = group.length;
    }
  });
  // Create subsets for all experiences
  const expSubsets = createSubsets(flattenedExperiences, maxLength);

  // Add the isExp flag for experiences
  expSubsets.forEach((subset: any) => {
    subset.forEach((exp: any) => {
      exp.isExp = true;
    });
  });

  // Create subsets for education if available
  const eduSubsets = Array.isArray(candidateEdu)
    ? createSubsets(candidateEdu, maxLength)
    : [];

  // Add the isEdu flag for education
  eduSubsets.forEach((subset: any) => {
    subset.forEach((edu: any) => {
      edu.isEdu = true;
    });
  });

  const expAndEdu = { exp: expSubsets, edu: eduSubsets };
  const cols = [...expAndEdu.exp, ...expAndEdu.edu];

  // Split into subsets for columns (first, second, third), ensuring each column has a maximum of 4 items
  const colsWithSubsets = [];
  for (let i = 0; i < cols.length; i += groupedExp.length) {
    colsWithSubsets.push(cols.slice(i, i + groupedExp.length));
  }

  return (
    <div style={{ position: 'relative' }}>
      {isShow &&
        colsWithSubsets?.map((obj: any, i: any) => {
          const isLanguage =
            (i == 0 && obj?.length < 2) || (i > 0 && obj?.length <= 2);
          return (
            <div
              key={i}
              style={{
                backgroundColor: '#111b2d',
              }}
              className="single_cdp_parent"
            >
              <Cdp
                iconToShow={iconToShow}
                jobData={jobData?.getJobDetails}
                matchData={canadidates[posIndex]?.candidates[index]}
                candidateData={candidate}
                isExperience={obj[0]?.[0]?.isExp}
                exp={obj[0]}
                isSecondColumnExp={obj[1]?.[0]?.isExp}
                secondColExp={obj[1]}
                isThirdColumnExp={obj[2]?.[0]?.isExp}
                showBenchmark={i == 0 ? true : false}
                thirdColExp={obj[2]}
                isEducation={(() => {
                  if (obj[0]?.[0]?.isEdu) {
                    return true;
                  } else if (obj[1]?.[0]?.isEdu) {
                    return true;
                  } else if (obj[2]?.[0]?.isEdu) {
                    return true;
                  } else {
                    return false;
                  }
                })()}
                edu={(() => {
                  if (obj[0]?.[0]?.isEdu) {
                    return obj[0];
                  } else if (obj[1]?.[0]?.isEdu) {
                    return obj[1];
                  } else if (obj[2]?.[0]?.isEdu) {
                    return obj[2];
                  }
                })()}
                isSecondColumnEdu={obj[0]?.[0]?.isEdu && obj[1]?.[0]?.isEdu}
                secondColEdu={obj[1]}
                isThirdColumnEdu={obj[2]?.[0]?.isEdu}
                thirdColEdu={obj[2]}
                isLastPage={isLanguage}
                isComposentation={i == 0 ? true : false}
              />
              {!isLanguage &&
                colsWithSubsets?.length - 1 == i &&
                candidate?.languages?.length && (
                  <Cdp
                    key={i + '3829'}
                    iconToShow={iconToShow}
                    jobData={jobData?.getJobDetails}
                    matchData={canadidates[posIndex]?.candidates[index]}
                    candidateData={candidate}
                    showBenchmark={false}
                    isLastPage={true}
                  />
                )}
            </div>
          );
        })}
    </div>
  );
};

export default ShowCompleteCdp;
