import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  Input,
  Divider,
  Row,
  Col,
  Card,
  Typography,
  Avatar,
  Select,
  message,
  DatePicker,
  Switch,
  notification,
} from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { Skill } from 'containers/BackOffice/Candidates/Profile/AutoFill/components';
import {
  useCreateCandidateAndMatchMutation,
  useSkillsQuery,
} from 'graphql/generated/graphql';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Axios from 'axios';
import moment, { Moment } from 'moment';

const { Option } = Select;

interface Experience {
  description: string;
  title: string;
  dateStarted: string;
  dateEnded: string;
  location?: string;
  organisation: {
    name: string;
    salesNavLink: string;
  };
  timePeriod: {
    startedOn: {
      month?: number;
      year?: number;
    };
    endedOn: {
      month?: number;
      year?: number;
    };
  };
}
interface Education {
  institutionName: string;
  degree: string;
  location?: string;
  fieldOfStudy: string;
  dateStarted: string;
  dateEnded: string;
  timePeriod: {
    startedOn: {
      year: number;
    };
    endedOn: {
      year: number;
    };
  };
}
interface Location {
  country?: string;
  city?: string;
  code?: string;
  coordinates?: { lat?: string; lng?: string };
}
interface Skill {
  name: string;
  numOfEndorsement: string;
}
interface DataType {
  description: string;
  location: string;
  name: string;
  img?: string;
  twitter: string;
  aboutSummaryText: string;
  salesNavLink: string;
  link: string;
  experience: Experience[];
  education: Education[];
  skills: Skill[];
}
interface Props {
  jobId: string;
}

const AddCandidate: React.FC<Props> = ({ jobId }) => {
  const [visible, setVisible] = useState(false);
  const [profileLink, setProfileLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType | null>(null);
  const [createCandidateAndMatchMutation, { loading: loading2 }] =
    useCreateCandidateAndMatchMutation();
  const {
    data: skillsList,
    loading: skillsLoading,
    error,
  } = useSkillsQuery({
    variables: {
      status: 'Approved',
      isArchived: false,
      isDeleted: false,
      isBO: true,
    },
  });

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: 'AIzaSyACXA9qapZNvq51WpF69eIH908sX_efNMs',
      options: {
        types: ['geocode'],
      },
    });
  // const [value, setValue] = useState([]);
  const locationRef = useRef<any>();

  //data states

  const [email, setEmail] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [skills, setSkills] = useState<{ name: string; id: string }[]>([]);
  const [customSkills, setCustomSkills] = useState<string[]>([]);
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [education, setEducation] = useState<Education[]>([]);

  //functions

  const handleURL = (event: any) => {
    console.log('Input Value:', event.target.value);
    setProfileLink(event.target.value);
  };

  const handleOk = () => {
    setLoading(true);
    addCandidateToJob(data);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const extractUsername = (url: string): string => {
    const trimmedUrl = url.endsWith('/') ? url.slice(0, -1) : url;
    const urlParts = trimmedUrl.split('/');
    const username = urlParts[urlParts.length - 1];
    return username;
  };

  const generateEmail = (input: string): string => {
    const email = input.replace(/\s/g, '').toLowerCase() + '@mailinator.com';
    return email;
  };

  const getLocation = (value: any) => {
    const locationObject = value?.split(',');
    let obj: any = {
      city: locationObject[0],
      country:
        locationObject.length > 1
          ? locationObject[locationObject.length - 1]
          : '',
      code: '0',
      // coordinates: { lat: 0.0, lng: 0.0 },
    };
    return obj;
  };

  const convertYearToMoment = (
    year: number | undefined
  ): Moment | undefined => {
    if (typeof year === 'number') {
      return moment().year(year);
    }
    return undefined;
  };

  const checkSkills = (skills: Skill[]) => {
    let newIds: { name: string; id: string }[] = [];
    let newCustomSkills: string[] = [];

    skills !== undefined &&
      skills.forEach((skill) => {
        const foundSkill = skillsList?.skills?.find(
          (item: any) => item.title === skill.name
        );

        if (foundSkill) {
          newIds.push({ id: foundSkill._id, name: foundSkill.title });
        } else {
          newCustomSkills.push(skill.name);
        }
      });

    setSkills(newIds);
    setCustomSkills(newCustomSkills);
  };

  const removeSkill = (id: string) => {
    const skillIndex = skills.findIndex((skill) => skill.id === id);

    if (skillIndex !== -1) {
      const newSkills = [...skills];
      newSkills.splice(skillIndex, 1);
      setSkills(newSkills);
    } else {
      const customSkillIndex = customSkills?.findIndex((skill) => skill === id);

      if (customSkillIndex !== -1) {
        const newCustomSkills = [...customSkills];
        newCustomSkills.splice(customSkillIndex, 1);
        setCustomSkills(newCustomSkills);
      }
    }
  };

  const handleExperienceChanges = (field: string, data: any, index: number) => {
    let newExperiences = [...experiences];
    let experience = { ...newExperiences[index] };

    console.log('here is date', data);

    switch (field) {
      case 'startDate':
        experience.timePeriod.startedOn.year = data;
        break;
      case 'endDate':
        experience.timePeriod.endedOn.year = experience.timePeriod.endedOn.year
          ? data
          : '';
        break;
      case 'current':
        experience.timePeriod.endedOn.year = data
          ? undefined
          : experience.timePeriod.startedOn.year;
        break;
      case 'Title':
        experience.title = data;
        break;
      case 'Organization':
        experience.organisation.name = data;
        break;
      case 'location':
        experience.location = data;
        break;
      default:
        break;
    }

    newExperiences[index] = experience;
    setExperiences(newExperiences);
  };

  const handleEducationChanges = (
    field: string,
    data: string,
    index: number
  ) => {
    console.log('here is education', education);
    let newEducations = [...education];
    let educationItem = { ...newEducations[index] };

    switch (field) {
      case 'institutionName':
        educationItem.institutionName = data;
        break;
      case 'degree':
        educationItem.degree = data;
        break;
      case 'fieldOfStudy':
        educationItem.fieldOfStudy = data;
        break;
      case 'startDate':
        educationItem.dateStarted = data;
        break;
      case 'endDate':
        educationItem.dateEnded = data;
        break;
      case 'location':
        educationItem.location = data;
        break;
      default:
        break;
    }

    newEducations[index] = educationItem;
    setEducation(newEducations);
  };

  const fetchData = async () => {
    let username = extractUsername(profileLink);
    setLoading(true);
    try {
      const response = await Axios({
        method: 'post',
        url: 'https://blonk-api-v3.herokuapp.com/linkedin-profile',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          userName: username,
        },
      });

      if (response.status !== 200) {
        message.error(response.data.error);
        setLoading(false);
        console.log('errors', response);
      } else {
        setData(response.data);
        let mail = generateEmail(response?.data?.name || '');
        setEmail(mail);
        setSummary(response?.data?.aboutSummaryText);
        checkSkills(response?.data?.skills);
        setExperiences(response?.data?.experience || []);
        setEducation(response?.data?.education || []);
        setLoading(false);
        setVisible(true);
      }
    } catch (error) {
      setLoading(false);
      console.log('error while fetching data', error);
      message.error('Error fetching data from LinkedIn');
    }
  };

  const uploadImageFromUrl = async (url: any, email: any) => {
    try {
      // Step 1: Load the image from the URL
      const response = await axios.get(url, { responseType: 'blob' });

      // Step 2: Convert the image blob to a File object
      const file = new File([response.data], 'image.jpg', {
        type: 'image/jpeg',
      });

      // Step 3: Create a FormData object and append the file
      const formData = new FormData();
      formData.append('file', file);

      // Step 4: Send the image to the backend
      const uploadUrl = `${process.env.REACT_APP_BASE_REST_URL}/upload/candidates/${email}`;
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Step 5: Handle the response
      if (uploadResponse.status === 200) {
        return uploadResponse.data.secure_url;
      } else {
        message.error(`${file.name} file upload failed.`);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      message.error('Image upload failed.');
    }
  };

  const addCandidateToJob = async (data: any) => {
    if (data?.img) {
      data.img = await uploadImageFromUrl(data?.img, email);
    }

    if (!summary?.length) {
      notification['warning']({
        message: 'Summary is required',
        description: error,
        duration: 3,
      });
      setLoading(false);
      return;
    }

    let payload = {
      jobId: jobId,
      email: email,
      password: 'enterBlonk123',
      firstName: data?.name.split(' ')[0],
      lastName: data?.name.split(' ')[1],
      summary: summary,
      position: data?.experience[0].title,
      company: data?.experience[0].organisation.name,
      country: data?.location,
      profilePicture: data?.img || '',
      resume: '',
      linkedIn: profileLink,
      // profileVideo: 'profileVideo.mp4',
      // coverLetter: 'coverLetter.pdf',
      skills: skills?.map((skill) => skill?.id),
      customSkills: customSkills,
      media: 'Auto_linkedIn',
      action: 'like',
      experiences: experiences.map((exp: Experience) => ({
        // Map the experiences to the required format
        position: exp?.title,
        company: exp?.organisation?.name,
        location: exp.location
          ? getLocation(exp.location)
          : { country: '', city: '' },
        startYear: exp?.timePeriod?.startedOn?.year?.toString() || '',
        endYear: exp?.timePeriod?.endedOn?.year?.toString() || null,
        // current: exp?.dateEnded === 'Present' ? true : false,
        current: exp?.timePeriod?.endedOn?.year ? false : true,
      })),
      educations: education.map((edu: Education) => ({
        // Map the experiences to the required format
        degree: edu?.degree,
        schoolName: edu?.institutionName,
        location: edu?.location
          ? getLocation(edu.location)
          : { country: '', city: '' },
        startYear: edu?.timePeriod?.startedOn?.year?.toString(),
        endYear: edu?.timePeriod?.endedOn?.year?.toString() || '',
        // current: edu?.dateEnded === 'Present' ? true : false,
        current: edu?.dateEnded || edu?.dateEnded === '' ? false : true,
      })),
      currentLocation: getLocation(data?.location),
      resumeName: '',
      // coverLetterName: 'JohnDoe_CoverLetter.pdf',
    };

    createCandidateAndMatchMutation({
      variables: {
        ...payload,
      },
    })
      .then((response) => {
        setLoading(false);

        if (response.errors && response.errors.length > 0) {
          response.errors.forEach((error) => {
            message.error(error.message);
          });
          setLoading(false);
        } else {
          setVisible(false);
          setLoading(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });

    console.log('here is the payloadoo', payload);
  };

  const isBtnDisabled = profileLink === '';

  return (
    <>
      <Card style={{ boxShadow: 'none', paddingBottom: '24px' }}>
        <Row gutter={16}>
          <Col span={8}>
            <Input
              width={360}
              value={profileLink}
              placeholder="LinkedIn profile url"
              onChange={handleURL}
            />
          </Col>
          <Col span={2}>
            <Button
              className="ant-btn-round ant-btn"
              style={{
                backgroundColor: isBtnDisabled ? '#eeeeee' : '#2E4A79',
                color: isBtnDisabled ? 'black' : 'white',
              }}
              onClick={fetchData}
              loading={loading}
              disabled={isBtnDisabled}
            >
              Add candidate
            </Button>
          </Col>
        </Row>
      </Card>
      <Modal
        visible={visible}
        width={1200}
        style={{ top: 20 }}
        title="Data fetched from linkedIn"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        {data && (
          <div style={{ padding: '10px' }}>
            <Row
              gutter={36}
              style={{
                alignItems: 'center',
                background: '#2e4a79',
                padding: '10px',
                marginBottom: '20px',
                borderRadius: '8px',
              }}
            >
              <Col span={4}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  {data?.img ? (
                    <img
                      src={data?.img}
                      className=" img-thumbnail rounded-circle linkedIn-Candidate"
                      alt="Candidate Profile img"
                    />
                  ) : (
                    <Avatar
                      src={
                        'https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255532-stock-illustration-profile-placeholder-male-default-profile.jpg'
                      }
                      size={120}
                    />
                  )}
                </div>
              </Col>
              <Col span={10}>
                <Typography style={{ fontWeight: 'bold', color: '#ffffff' }}>
                  {data.name}
                </Typography>
                <Typography style={{ color: '#ffffff' }}>
                  {data.description}
                </Typography>
                <Input
                  placeholder="startDate"
                  //   defaultValue={generateEmail(data?.name)}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ marginTop: '10px' }}
                />
              </Col>
              <Col span={10}>
                <Typography style={{ color: '#ffffff' }}>
                  {data.location}
                </Typography>
                <Typography style={{ color: '#ffffff' }}>
                  {data.link}
                </Typography>
              </Col>
            </Row>

            <h4>Summary</h4>
            <BorderlessTextArea
              placeholder="Summary"
              //   defaultValue={data.aboutSummaryText}
              required
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
            <Divider />
            <h4>Experiences</h4>
            {experiences.map((exp, index) => (
              <div
                key={index}
                style={{
                  borderLeft: '2px solid #2e4a79',
                  marginBottom: '24px',
                  paddingLeft: '10px',
                }}
              >
                <div style={{ display: 'inline-flex' }}>
                  {/* <BorderlessInput
                    placeholder="startDate"
                    defaultValue={exp?.timePeriod?.startedOn?.year}
                    onChange={(e) =>
                      handleExperienceChanges(
                        'startDate',
                        e.target.value,
                        index
                      )
                    }
                  />
                  <BorderlessInput
                    placeholder="endDate"
                    defaultValue={exp?.timePeriod?.endedOn?.year || 'Present'}
                    onChange={(e) =>
                      handleExperienceChanges('endDate', e.target.value, index)
                    }
                  /> */}
                  <BorderlessDatePicker
                    value={convertYearToMoment(
                      exp?.timePeriod?.startedOn?.year
                    )}
                    onChange={(value) =>
                      handleExperienceChanges('startDate', value?.year(), index)
                    }
                    picker="year"
                    format="YYYY"
                  />
                  <BorderlessDatePicker
                    value={convertYearToMoment(exp?.timePeriod?.endedOn?.year)}
                    disabled={exp?.timePeriod?.endedOn?.year ? false : true}
                    placeholder="Present"
                    size="small"
                    onChange={(value) =>
                      handleExperienceChanges('endDate', value?.year(), index)
                    }
                    picker="year"
                    format="YYYY"
                  />
                  <Typography
                    style={{
                      padding: '0px 10px',
                    }}
                  >
                    current
                  </Typography>
                  <Switch
                    onChange={(e) =>
                      handleExperienceChanges('current', e, index)
                    }
                    // checked={exp?.timePeriod?.endedOn.current }
                    defaultChecked={
                      exp?.timePeriod?.endedOn?.year ? false : true
                    }
                  />
                </div>
                <BorderlessInput
                  placeholder="Title"
                  defaultValue={exp.title}
                  onChange={(e) =>
                    handleExperienceChanges('Title', e.target.value, index)
                  }
                />
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <BorderlessInput
                    placeholder="Organization"
                    defaultValue={exp.organisation.name}
                    onChange={(e) =>
                      handleExperienceChanges(
                        'Organization',
                        e.target.value,
                        index
                      )
                    }
                  />
                  |
                  {/* <BorderlessInput
                    placeholder="location"
                    defaultValue={exp.location}
                    onChange={(e) =>
                      handleExperienceChanges('location', e.target.value, index)
                    }
                  /> */}
                  <Select
                    style={{ minWidth: '240px' }}
                    showSearch
                    status={exp.location ? '' : 'error'}
                    className="experience-location-selector"
                    ref={locationRef}
                    defaultValue={exp.location || 'location'}
                    onSearch={(value: any) => {
                      getPlacePredictions({ input: value });
                      handleExperienceChanges('location', value, index);
                    }}
                    onChange={(value: any) => {
                      handleExperienceChanges('location', value, index);
                    }}
                    loading={isPlacePredictionsLoading}
                    filterOption={(input, option: any) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {placePredictions &&
                      placePredictions.map((company: any) => {
                        return (
                          <Option
                            key={company.description}
                            value={company.description}
                          >
                            {company.description}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            ))}
            <Divider />
            <h4>Education</h4>
            {education.map((edu, index) => (
              <div
                key={index}
                style={{
                  borderLeft: '2px solid #2e4a79',
                  marginBottom: '8px',
                  paddingLeft: '10px',
                }}
              >
                <div style={{ display: 'inline-flex' }}>
                  <BorderlessInput
                    placeholder="startDate"
                    defaultValue={edu.timePeriod.startedOn.year}
                    onChange={(e) =>
                      handleEducationChanges('startDate', e.target.value, index)
                    }
                  />
                  <BorderlessInput
                    placeholder="endDate"
                    defaultValue={edu.timePeriod.endedOn.year}
                    onChange={(e) =>
                      handleEducationChanges('endDate', e.target.value, index)
                    }
                  />
                </div>
                <BorderlessInput
                  placeholder="Degree"
                  defaultValue={`${edu.degree} ${edu?.fieldOfStudy}`}
                  onChange={(e) =>
                    handleEducationChanges('degree', e.target.value, index)
                  }
                />
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <BorderlessInput
                    placeholder="Institute"
                    defaultValue={edu.institutionName}
                    onChange={(e) =>
                      handleEducationChanges(
                        'institutionName',
                        e.target.value,
                        index
                      )
                    }
                  />
                  |
                  {/* <BorderlessInput
                    placeholder="location"
                    defaultValue={edu?.location || data.location}
                    onChange={(e) =>
                      handleEducationChanges('location', e.target.value, index)
                    }
                  /> */}
                  <Select
                    style={{ minWidth: '240px' }}
                    showSearch
                    // status={edu.location ? '' : 'error'}
                    className="experience-location-selector"
                    ref={locationRef}
                    defaultValue={edu.location || 'location'}
                    onSearch={(value: any) => {
                      getPlacePredictions({ input: value });
                      handleEducationChanges('location', value, index);
                    }}
                    onChange={(value: any) => {
                      handleEducationChanges('location', value, index);
                    }}
                    loading={isPlacePredictionsLoading}
                    filterOption={(input, option: any) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {placePredictions &&
                      placePredictions.map((company: any) => {
                        return (
                          <Option
                            key={company.description}
                            value={company.description}
                          >
                            {company.description}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            ))}
            <Divider />
            <h4>Skills</h4>
            <Row>
              {skills?.map((skill, index) => (
                <Skill
                  key={`custom_${index}`}
                  title={skill.name}
                  id={skill.id}
                  removeSkill={removeSkill}
                />
              ))}
              {customSkills?.map((name, index) => (
                <Skill
                  key={`custom_${index}`}
                  title={name}
                  id={name}
                  removeSkill={removeSkill}
                />
              ))}
            </Row>
          </div>
        )}
      </Modal>
    </>
  );
};

const BorderlessInput = styled(Input)`
  border: none;
  && {
    padding: 2px 5px 2px 5px; // Reduce padding
    margin-bottom: 1px; // Add bottom margin
  }
`;
const BorderlessTextArea = styled(TextArea)`
  border: none;
`;

const BorderlessDatePicker = styled(DatePicker)`
  border: none;
  && {
    height: 30px; // Reduce padding
    margin-bottom: 1px;
  }
`;

const MyDivider = styled(Divider)`
  margin: 10px 0px;
`;

export default AddCandidate;
