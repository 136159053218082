import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import AvatarPlaceholder from '../../../../../../assets/icons/DetailedProfile/experiencePlaceholder.svg';
import _ from 'lodash';

interface PropsType {
  index: any;
  experience: any;
  candidateId: string;
  onEdit?: (id: any, experience: any) => void;
}

const Styles = {
  root: {
    paddingLeft: 10,
    display: 'flex',
    gap: '1px',
    maxWidth: '320px',
    flex: '85%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  year: {
    fontStyle: 'italic',
    fontSize: 10,
    fontWeight: 500,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  position: {
    fontWeight: 400,
    fontSize: 15,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  company: {
    fontWeight: 600,
    fontSize: 15,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  totalYears: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(0,0,0,.5)',
    marginTop: '-1px',
    marginBottom: '-5px',
  },
  avatar: {
    backgroundColor: '#F5F7F9',
  },
  placeholderAvatar: {
    backgroundColor: '#ccc', // Placeholder background color
    marginRight: '2px',
  },
  actionWrapper: {
    display: 'flex',
  },
};

const SingleExperience: React.FC<PropsType> = ({ experience }) => {
  const [imgError, setImgError] = useState(false);

  const handleImageError = () => {
    setImgError(true);
    return false;
  };
  const convertToYear = (year: string | null): number => {
    if (!year) return new Date().getFullYear();
    if (year === 'Present') return new Date().getFullYear();
    return parseInt(year.split('-')[0], 10);
  };
  const calculateTotalYearsSpent = (experience: any): any => {
    const experiences = [experience, ...(experience?.mergedData || [])];
    experiences.sort(
      (a: any, b: any) =>
        convertToYear(a.startYear) - convertToYear(b.startYear)
    );

    const earliestStartYear = convertToYear(experiences[0]?.startYear);
    const latestEndYear = convertToYear(
      experiences.find((e: any) => e?.current)?.endYear ||
        experiences[experiences.length - 1]?.endYear ||
        'Present'
    );

    const val = latestEndYear - earliestStartYear;
    return val > 0 ? val : '';
  };
  useEffect(() => {
    if (
      experience?.logo == null ||
      experience?.logo == undefined ||
      experience?.logo == ''
    ) {
      setImgError(true);
    }
  }, [experience?.logo, experience]);
  const totalYearsSpent: any = calculateTotalYearsSpent(experience);

  if (experience?.current) {
    experience.endYear = 'Present';
  }

  if (experience?.mergedData) {
    experience.mergedData = experience.mergedData.sort((s: any) =>
      s?.current ? -1 : 1
    );
  }

  experience = _.cloneDeep(experience);

  return (
    <div
      style={{
        ...Styles.wrapper,
        flexDirection: 'row',
        alignItems: 'end',
      }}
    >
      {' '}
      <div style={{ ...Styles.root, wordWrap: 'break-word' }}>
        <div style={{ display: 'flex', justifyContent: 'start', gap: '16px' }}>
          {!imgError ? (
            <Avatar
              style={{
                ...Styles.avatar,
                backgroundImage: `url(${experience?.logo})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                objectFit: 'cover',
                flexShrink: 0,
                margin: '5px',
                width: '28px', // Ensure this matches the size of the Avatar
                height: '28px', // Ensure this matches the size of the Avatar
              }}
              size={28}
              // src={experience?.logo}
              onError={handleImageError}
            />
          ) : (
            <span
              style={{
                ...Styles.placeholderAvatar,
                flexShrink: 0,
                height: '28px',
                width: '28px',
                margin: '5px',
                backgroundColor: '#f9f6f7',
                borderRadius: '50%',
              }}
            >
              <img
                style={{
                  ...Styles.placeholderAvatar,
                  height: '18px',
                  width: '18px',
                  flexShrink: 0,
                  background: '#f9f6f7',
                  margin: '5px',
                  marginTop: '2px',
                }}
                src={AvatarPlaceholder}
                alt="placeholder"
              />
            </span>
          )}

          <div style={{ marginLeft: '-8px' }}>
            <p style={Styles.company}>{experience?.company}</p>
            {!experience?.mergedData && (
              <>
                <span
                  style={{
                    color: 'rgba(0,0,0,0.5)',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  {experience.startYear} {experience.endYear && '-'}{' '}
                  {experience?.endYear}
                </span>
                <p style={{ ...Styles.position }}>{experience?.position}</p>
                <div className="">
                  <span
                    style={{
                      color: '#424952',
                      fontSize: '12px',
                      fontWeight: '400',
                    }}
                  >
                    {experience?.location?.city && experience?.location?.country
                      ? `${experience?.location?.city}, ${experience?.location?.country}`
                      : experience?.location?.city &&
                        !experience?.location?.country
                      ? `${experience?.location?.city}`
                      : ''}
                  </span>
                </div>
              </>
            )}

            {totalYearsSpent &&
              !isNaN(totalYearsSpent) &&
              experience?.mergedData && (
                <p style={Styles.totalYears}>{totalYearsSpent} years</p>
              )}

            {experience?.mergedData?.length > 0 &&
              experience.mergedData.map((d: any, i: any) => {
                if (d?.current) {
                  d.endYear = 'Present';
                }
                if (d.position !== experience.position || true) {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        marginTop: '20px',
                        marginLeft: '-20px',
                      }}
                      key={i}
                    >
                      <div style={{ marginTop: '10px' }}>
                        <div
                          style={{
                            height: '9px',
                            width: '9px',
                            backgroundColor: '#418394',
                            borderRadius: '100%',
                          }}
                        />
                        <div
                          style={{
                            height: '36px',
                            width: '1.2px',
                            backgroundColor: '#418394',
                            marginTop: '-4px',
                            marginBottom: '-4px',
                            marginLeft: '4.1px',
                          }}
                        />
                        <div
                          style={{
                            height: '9px',
                            width: '9px',
                            backgroundColor: '#418394',
                            borderRadius: '100%',
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          marginTop: '1px',
                          marginLeft: '3px',
                        }}
                      >
                        <span
                          style={{
                            color: 'rgba(0,0,0,0.5)',
                            fontSize: '12px',
                            fontWeight: '400',
                          }}
                        >
                          {d.startYear} - {d.endYear}
                        </span>
                        <div style={Styles.position}>{d.position}</div>
                        <div className="">
                          <span
                            style={{
                              color: '#424952',
                              fontSize: '12px',
                              fontWeight: '400',
                            }}
                          >
                            {d?.location?.city && d?.location?.country
                              ? `${d?.location?.city}, ${d?.location?.country}`
                              : d?.location?.city && !d?.location?.country
                              ? `${d?.location?.city}`
                              : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
                // return null;
              })}
            {/* <div className="">
            <p style={Styles.year}>
              {experience?.startYear}
              {experience?.current
                ? '-Current'
                : experience?.endYear && `-${experience?.endYear}`}
            </p>
            <p style={Styles.position}>{experience?.position}</p>
            <p style={Styles.company}>
              {experience?.company && '@' + experience?.company}
              <span style={{ fontSize: 10, fontWeight: 400 }}>
                {experience?.location?.city && experience?.location?.country
                  ? '| ' +
                    experience?.location?.city +
                    ', ' +
                    experience?.location?.country
                  : experience?.location?.city &&
                    experience?.location?.country == ''
                  ? '| ' + experience?.location?.city
                  : ''}
              </span>
            </p>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleExperience;
