import React from 'react';

interface PropsType {
  experience: any;
}

const Styles = {
  root: {},
  commentBox: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: 'rgba(188, 150, 35, 0.03)',
    // marginTop: 10,
    borderRadius: 9,
    marginBottom: 8,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.14)',
    border: 'solid 0.5px #DADADA',
  },
  body: {
    margin: 0,
    color: '#495057',
    fontSize: 13,
    fontWeight: 400,
    fontStyle: 'italic',
  },
  heading: {
    margin: 0,
    color: '#012653',
    fontSize: 14,
    fontWeight: 500,
  },
};

const ExperienceComment: React.FC<PropsType> = ({ experience }) => {
  const { comments, reasonForChange } = experience;
  //\n replace with br tag
  const formattedComment = comments?.replace(/\n/g, '<br />');
  const formattedReasaonFroChange = reasonForChange?.replace(/\n/g, '<br />');
  return (
    <div style={Styles.commentBox}>
      {comments != null && comments?.length > 0 && (
        <>
          <p style={Styles.heading}>Comments:</p>
          {/* <p style={Styles.body}>{comments}</p> */}
          <div
            style={Styles.body}
            dangerouslySetInnerHTML={{ __html: formattedComment }}
          />
        </>
      )}
      {/* {reasonForChange != null && reasonForChange?.length > 0 && ( */}
      {/* <> */}
      {/* {comments == null || comments == '' ? null : ( */}
      {/* <span */}
      {/* style={{ */}
      {/* display: 'flex', */}
      {/* border: '1px #00000040 dashed', */}
      {/* margin: 2, */}
      {/* }} */}
      {/* ></span> */}
      {/* )} */}
      {/* <p style={Styles.heading}>Reasons for change:</p> */}
      {/* <div */}
      {/* style={Styles.body} */}
      {/* dangerouslySetInnerHTML={{ __html: formattedReasaonFroChange }} */}
      {/* /> */}
      {/* <p style={Styles.body}>{reasonForChange}</p> */}
      {/* </> */}
      {/* )} */}
    </div>
  );
};

export default ExperienceComment;
